.main-loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: #2b2b2b; */
  background-color: #f5f5f5;
  animation: animate 2s ease-in-out infinite;
}

/* @keyframes animate {
  0%,
  100% {
    clip-path: polygon(0 0, 59% 0, 30% 100%, 0 100%);
  }

  50% {
    clip-path: polygon(0 0, 71% 0, 39% 100%, 0 100%);
  }
} */

@keyframes animate {
  0%,
  100% {
    clip-path: circle(0% at 50% 50%);
  }

  50% {
    clip-path: circle(7% at 50% 50%);
  }
}

/* ----------------------------------------------- */

.main-loader-wrapper-hidden {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  animation: animateHide 2s ease-in-out 1;
}

@keyframes animateHide {
  0% {
    clip-path: circle(0% at 50% 50%);
    opacity: 1;
  }
  100% {
    opacity: 0.1;
    clip-path: circle(120% at 50% 50%);
  }
}
